import React from "react"
import Helmet from "react-helmet"
import LayoutMain from "../components/layouts/LayoutMain"
import "../styles/homepage.scss"


const IndexPage = (props) => (
      <LayoutMain className="homepage">
        <Helmet>
          <title>Blue Tenon</title>
          <meta
            name="description"
            content="Blue Tenon"
          />
        </Helmet>
        <div className="container-homepage">
          <div className="section-main">
            <h1>Blue Tenon is <a href="https://www.linkedin.com/in/thomaschall" target="_blank" rel="noopener noreferrer">my</a> playground for testing various digital experiements.</h1>
          </div>
        </div>
      </LayoutMain>
    )

export default IndexPage